import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer class="py-3 bg-dark fixed-bottom">
        <div class="container">
          <p class="m-0 text-center text-white">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/trademarkvapescbd/"
            >
              <i class="fa-brands fa-instagram fa-xl socials"></i>{" "}
            </a>
            <a class="d-none"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/trademarkvapesuk/"
            >
              <i class="fa-brands fa-square-facebook fa-xl socials"></i>{" "}
            </a>
          </p>
          <p class="m-0 text-center text-white">
            2022 &copy; Trademark Vapes Ltd. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
