import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light page-title">contact.</h1>
            <h2>contact.</h2>
            <li>
              <i class="fa-solid fa-phone"></i>+44 (0) 20 8127 2580
            </li>
            <li>
              <i class="fa-solid fa-paper-plane"></i>
              <a href="mailto:trademarkvapes@mail.com">
                trademarkvapes@mail.com
              </a>
            </li>
            <h2>socials.</h2>
            <li>
              <i class="fa-brands fa-instagram"></i>follow us on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/trademarkvapescbd/"
              >
                instagram.
              </a>
            </li>
            <li class="d-none">
              {" "}
              <i class="fa-brands fa-square-facebook"></i>follow us on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/trademarkvapesuk/"
              >
                facebook.
              </a>
            </li>
            <h2>where.</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.95337909658!2d-0.0026110000000000005!3d51.4590124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760397d4dba553%3A0x569579bba1fa0d24!2sTrademark%20Vapes%20%26%20CBD%20%E2%84%A2!5e0!3m2!1sen!2suk!4v1623681987391!5m2!1sen!2suk"
              width="500"
              height="400"
              styles="border:0"
              allowfullscreen=""
              loading="lazy"
              title="Trademark Vapes Location"
              class="bottom-pad"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
