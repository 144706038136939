import React from "react";

function About() {
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light page-title">about.</h1>
            <h2>when.</h2>
            <li>Mon, Tue // 11am to 7pm</li>
            <li>Wed // Closed</li>
            <li>Thu, Fri // 11am to 7pm</li>
            <li>Sat // 12pm to 6pm</li>
            <li>Sun // 12pm to 4pm</li>
            <h2>reviews.</h2>
            <li class="bottom-pad">
              Check out our{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.google.com/search?safe=active&ei=wxRSYP62Moe8gQb6m6cg&q=Trademark+Vapes+%26+CBD+%E2%84%A2&oq=Trademark+Vapes+%26+CBD+%E2%84%A2&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCgATIECCEQFToHCCEQChCgAVDbDFjVPGC8R2gIcAB4AIABwAKIAYUIkgEHMy4xLjEuMZgBAKABAaABAqoBB2d3cy13aXrAAQE&sclient=gws-wiz&ved=0ahUKEwi-pOSqx7fvAhUHXsAKHfrNCQQQ4dUDCA4&uact=5#lrd=0x48760397d4dba553:0x569579bba1fa0d24,1,,,"
              >
                Google reviews{" "}
              </a>
              ⭐️⭐️⭐️⭐️⭐️
            </li>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
