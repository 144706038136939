import React from "react";

function Home() {
  return (
    <div className="home">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-7">
            <img
              class="img-fluid rounded mb-4 mb-lg-0"
              src="http://placehold.it/900x400"
              alt=""
            />
          </div>
          <div class="col-lg-5">
            <h1 class="font-weight-light page-title">Trademark Vapes & CBD ™</h1>
            <p>
              South East London's primary vendor of e-liquids, CBD and hardware.
              Carefully curated and true to the craft.
            </p>
            <h2>where.</h2>
            <i class="fa-solid fa-signs-post"></i>Find us at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://g.page/TMVapes?share"
            >
              7 Manor Park Parade, London SE13 5PB.
            </a>
            <h2>e-shop.</h2>
            <p>
            <i class="fa-solid fa-cart-shopping"></i>Buy Trademark Vapes products online now at{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trademarkvapes.bigcartel.com/"
                class="bottom-pad"
              >
                Big Cartel.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
